import Logo from "../components/Logo";
import {NavLink, useNavigate} from "react-router-dom";
import {login, memberlogin} from "../store/user";
import {useDispatch, useSelector} from "react-redux";
import { useRef, useEffect, useState} from "react";
import { WEBTOKEN, LG, WEBNAME, VDO } from '../store/config'
import { Spinner } from "react-bootstrap";
import VedioMoal from './Lotto/ModalVDO'
import PageHeader from "../components/PageHeader";
function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const showVDO = () => {
     setShow(true)
    }
    const user = useSelector(state => state.user)

    const register = async () => {
        return navigate("/contact");
    }
    /*
    const signIn = async () => {
        await dispatch(login());
        return navigate("/");
    }
    */

    useEffect(()=> {
        if(localStorage.getItem('access_token')) {
            return navigate("/");
        }
    }, [])
    const txtusername = useRef(null)
    const txtpassword = useRef(null)

    
    const signIn = async () => {

        const username = txtusername.current.value;
        const password = txtpassword.current.value;
        if(!username){
            txtusername.current.focus()
        } else if(!password && username){
            txtpassword.current.focus()
        } else {
            const data = {
                username,
                password,
                WEBTOKEN
            }
            if(user?.isLoadingLogin === false) {
                dispatch(memberlogin(data));
            }
            
        }
        
        /*
        await dispatch(login());
        return navigate("/");
        */
    }

    return (
        <div className={'container'}>
            <div className={'row justify-content-center align-items-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} data-aos="fade-up">

                    <div className={'text-center d-flex justify-content-between'}>
                   
                    <NavLink to={'/'}>
                                    <div className={'w-100 btn btn-outline-primary btn-sm'}><img class="img-fluid menu-icon" height={22} width={22} src="/images/icon/home.svg" alt="menu" /> หน้าแรก</div>
                                </NavLink>
                       
                       
                    </div>

                    <div className={'text-center'}>
                   
                 
                       <div className={'mb-3'}>
                           <Logo style={{maxHeight: '100px'}}/>
                       </div>
                      
                   </div>

                    <div className={'card card-green mt-4 py-3'}>
                        <div className={'card-body'}>
                            <h3 className={'text-center'}>
                                เข้าสู่ระบบ
                            </h3>

                            <div className={'text-center mb-4 text-secondary'}>
                            ป้อนข้อมูลเบอร์มือถือที่ท่านลงทะเบียนไว้​. <br/>พร้อมรหัสผ่านของท่าน.
                            </div>

                            <div className={'d-flex gap-2 mb-3'}>
                                <input ref={txtusername} className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'เบอร์มือถือ'}/>
                            </div>

                            <div className={'d-flex gap-2 mb-4'}>
                                <input type="password" ref={txtpassword} className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'รหัสผ่าน'}/>
                            </div>

                            {
                        user.item?.status == 3 && <div className="text-dangerx text-center">ไม่พบข้อมูลสมาชิก กรุณาลองใหม่อีกครั้ง</div>
                }

                            <div className={'text-center mb-3'}>
                                {
                                    user?.isLoadingLogin === true ? <div className={'w-100 btn btn-primary btn-lg'}>เข้าสู่ระบบ <Spinner size="sm" /></div> : <div className={'w-100 btn btn-primary btn-lg'} onClick={signIn}>เข้าสู่ระบบ</div>
                                }
                                
                            </div>

                            <div className="separator my-4">ยังไม่เป็นสมาชิก</div>

                            <div className={'text-center mb-3'}>
                                <NavLink to={'/register'}>
                                    <div className={'w-100 btn btn-outline-primary btn-lg'}>สมัครสมาชิก</div>
                                </NavLink>
                            </div>

                            <div className={'text-center mb-3'}>
                                <div className={'w-100 btn btn-danger btn-lg'} onClick={()=>register()}>
                                    ติดต่อ - สอบถาม
                                </div>
                            </div>
                         
                            
                        </div>
                    </div>
                </div>
            </div>
            <VedioMoal filename='register' show={show} onHide={()=>setShow(false)}/>
            <div className="linefixed" onClick={()=>navigate('/contact')}><img width="150" height="231" src="/images/linethai.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/linethai.png 150w, /images/linethai.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
    );
}

export default Login;
