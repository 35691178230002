import './Lot2day.scss';
import './index.scss';
import {useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom'

// layout
import DefaultLayout from "./layout/Default";
import MainLayout from "./layout/Main";

// page
//import Home from "./page/Home44"; // Skyloy เท่านััน
import Home from "./page/Home";
import Login from "./page/Login";
import Logout from "./page/Logout";
import Register from "./page/Register";
import PaymentInfo from "./page/PaymentInfo";
import Contact from "./page/Contact";

import Affiliate from "./page/Affiliate";
import Contacts from "./page/Contacts";
import Profile from "./page/Profile";
import History from "./page/History";
import Tunsamai from "./page/Lotto/Tunsamai";
import TunsamaiSelect from "./page/Lotto/TunsamaiSelect";
import PrivateRoute from "./utils/PrivateRoute";
import Lotto from "./page/Lotto/Index";
import Stock from "./page/Lotto/Stock";
import StockSelect from "./page/Lotto/StockSelect";
import StockSeelctLink from "./page/Lotto/StockSeelctLink";
import Deposit from "./page/Deposit";
import Withdraw from "./page/Withdraw";
import HuayHoon from "./page/Bill/HuayHoon";
import HuayThai from "./page/Bill/HuayThai";
import TunsamaiPayment from "./page/Lotto/TunsamaiPayment";
import DepositConfirm from "./page/Lotto/DepositConfirm";
import TunsamaiSummary from "./page/Lotto/TunsamaiSummary";
import StockSummary from "./page/Lotto/StockSummary";
import MyBill from "./page/Bill/MyBill";
import MyBillHoon from "./page/Bill/MyBillHoon";
import Result from "./page/Bill/Result";


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<DefaultLayout/>}>
                <Route path="/login" index element={<Login/>}/>
                <Route path="/logout" index element={<Logout/>}/>
                <Route path="/register" index element={<Register/>}/>
                <Route path="/payment-info" index element={<PaymentInfo/>}/>
                <Route path="/contact" index element={<Contact/>}/>

                <Route path="/lotto/tunsamai" index element={<Tunsamai/>}/>
                <Route path="/lotto/tunsamai/select" index element={<TunsamaiSelect/>}/>
                <Route path="/lotto/tunsamai/depositconfirm" index element={<PrivateRoute><DepositConfirm/></PrivateRoute>}/>
                <Route path="/lotto/tunsamai/payment" index element={<PrivateRoute><TunsamaiPayment/></PrivateRoute>}/>
                <Route path="/lotto/tunsamai/summary" index element={<PrivateRoute><TunsamaiSummary/></PrivateRoute>}/>

                <Route path="/lotto/stocks/summary" index element={<PrivateRoute><StockSummary/></PrivateRoute>}/>
                <Route path="/lotto/stock" index element={<Stock/>}/>
                <Route path="/lotto/stocklink/:huaysubId" index element={<PrivateRoute><StockSeelctLink/></PrivateRoute>}/>
                <Route path="/lotto/stock/:huaysubId" index element={<PrivateRoute><StockSelect/></PrivateRoute>}/>
                

                <Route path="/deposit" index element={<PrivateRoute><Deposit/></PrivateRoute>}/>
                <Route path="/withdraw" index element={<PrivateRoute><Withdraw/></PrivateRoute>}/>
            </Route>

            <Route path="/" element={<MainLayout/>}>
                <Route index element={<Home/>}/>

                <Route path="/lotto" index element={<Lotto/>}/>

                <Route path="/bill/huay-thai" element={<PrivateRoute><HuayThai/></PrivateRoute>}/>
                <Route path="/bill/huay-hoon" element={<PrivateRoute><HuayHoon/></PrivateRoute>}/>


                <Route path="/result" element={<PrivateRoute><Result/></PrivateRoute>}/>
                <Route path="/bill/huay-thai/:roundId" element={<PrivateRoute><MyBill/></PrivateRoute>}/>
                <Route path="/bill/huay-hoon/:roundId" element={<PrivateRoute><MyBillHoon/></PrivateRoute>}/>

                <Route path="/affiliate" index element={<PrivateRoute><Affiliate/></PrivateRoute>}/>
                <Route path="/contacts" index element={<PrivateRoute><Contacts/></PrivateRoute>}/>
                <Route path="/profile" index element={<PrivateRoute><Profile/></PrivateRoute>}/>
                <Route path="/history" index element={<PrivateRoute><History/></PrivateRoute>}/>
            </Route>
        </>
    ), {
        basename: process.env.PUBLIC_URL,
    }
)

function App() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <RouterProvider router={router}/>
    );
}

export default App;
