import {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader";
import {numberWithCommas} from "../../utils/math";
import {Nav, Spinner} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";
import BillMenu from "../../components/BillMenu";
import { huaybillmember} from '../../store/user'
import moment from "moment";

function HuayHoon() {
    const dispatch = useDispatch()
    const {  isLoading, huayhoon, totalhun} = useSelector(state=>state.user)
    
    useEffect(()=> {
        dispatch(huaybillmember({}))
    },[])
    const [currentRound] = useState([
        {id: 'xxxxxxxxxxx88596', date: '09-06-2023 20:00:00', amount: 3000, result: [16, 56, 96]},
        {id: 'xxxxxxxxxxx88597', date: '09-06-2023 20:00:00', amount: 6000, result: [12, 34, 22]},
    ])

    const [historyRound] = useState([
        {id: 'xxxxxxxxxxx88596', date: '09-06-2023 20:00:00', amount: 3000, result: [44, 45, 67], win: 0},
        {id: 'xxxxxxxxxxx88597', date: '09-06-2023 20:00:00', amount: 4000, result: [16], win: 1},
    ])

    return (
        <div className={'container'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo/>
                    {/* end page title */}
                   
                    <BillMenu activePath={'bill'}/>
                    {
                        isLoading === true ? <div>
                        <div style={{height:80}}></div>
                        <div className="ring">LOADING
                    <span className='span1'></span>
                  </div>
                  <div style={{height:180}}></div>
                 
                  </div> :  <div className={'bill'}>
                        {/* tab menu */}
                        {/* tab menu */}
                        <div className={'bill-tab'}>
                            <Nav variant="tabs" defaultActiveKey="huay-hoon"
                                 justify>
                                <Nav.Item className={'pe-1'}>
                                    <Nav.Link eventKey="huay-thai" as={Link} to={`/bill/huay-thai`}>
                                        <img className="icon" alt={'menu'}
                                             src={`${process.env.PUBLIC_URL}/images/icon/tunsamai_active.png`}/>
                                        รัฐบาลไทย
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item className={'ps-1'}>
                                    <Nav.Link eventKey="huay-hoon" as={Link} to={`/bill/huay-hoon`}>
                                        <img className="icon" alt={'menu'}
                                             src={`${process.env.PUBLIC_URL}/images/icon/namsud_gray.png`}/>
                                        หวยหุ้น
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        {/* end  tab menu */}
                        {/* end  tab menu */}

                        <div className={'card card-green mb-5'}>
                            <div className={'card-body'}>
                                <div className={'mb-5'}>

                                    {/* countdown */}
                                    <div className={'card card-dark mb-1'}>
                                        <div className={'card-body'}>
                                            <div className={'text-center  text-primary'}>
                                            บิลที่ยังไม่ออกผลรางวัล
                                            </div>
                                        </div>
                                    </div>
                                    {/* end countdown */}
                                  
                                     {totalhun > 0 && <div className="p-2 bmoms">ยอดซื้อทั้งหมด {numberWithCommas(totalhun)} บาท</div>}
                                    
                                    {/* current */}
                                    {huayhoon && huayhoon.filter(x=>parseInt(x.status) === 3 || parseInt(x.status) === 2).map(data => {
                                        return <NavLink to={`/bill/huay-hoon/${data.id}`}
                                                        className={'text-decoration-none'}
                                                        key={data.id}>
                                            <div className={'card card-transparent mb-3'}>
                                                <div className={`card-body ${parseInt(data.status) === 2 && `bg-top`} ${parseInt(data.status) === 3 && `bg-top2`}`}>
                                                <div className="d-flex justify-content-between">
                                                    
                                    <div>
                                        <div>{data.nameth} งวดที่ : <span className="ball3x">{data.no}</span></div>
                                        <div >วันที่ : <span className="ball3">{moment(data.startdate).format("DD/MM/YYYY")}</span></div>
                                    </div>
                                   
                                    <div className="dimg2">
                                    <img src={`/images/${data.photo}.png`} />
                                    </div>
                                    
                                   
                                                    </div>
                                                    <div className={'row mb-3 align-items-start boderline'}>
                                                        <div className={'col-auto text-center'}>
                                                            <div>
                                                                {
                                                                    parseInt(data.status) === 2 ? <img className="img-fluid" alt={'result'}
                                                                    src={`${process.env.PUBLIC_URL}/images/icon/close.png`}
                                                                    style={{height: "23px"}}/> : <img className="img-fluid" alt={'result'}
                                                                    src={`${process.env.PUBLIC_URL}/images/icon/help.png`}
                                                                    style={{height: "23px"}}/>
                                                                }
                                                                
                                                            </div>
                                                            {
                                                                parseInt(data.status) === 2 ?  <div>
                                                                 ยกเลิกบิล
                                                                </div> :  <div>
                                                            รอผล 
                                                            </div>
                                                            }
                                                           
                                                        </div>

                                                        <div className={'col'}>
                                                         
                                                            <div>
                                                            เลขบิล : {data.billcode}
                                                            </div>
                                                            <div className={'mb-1'}>
                                                            วันที่ซื้อ : {data.createdAt}
                                                            </div>
                                                        </div>

                                                        <div className={'col-auto text-end'}>
                                                            <div>
                                                            ยอดรวม
                                                            </div>
                                                            <div className={'text-primary'}>
                                                                {numberWithCommas(data.amount)}  บาท
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </NavLink>
                                    })}
                                </div>
                                {/* end current */}

                                {/* lasted round */}
                                <div className={'card card-dark mb-3'} >
                                    <div className={'card-body'}>
                                        <div className={'row ่justify-content-center'}>
                                           
                                            <div className={'text-center  text-primary'}>
                                            บิลที่ออกผลรางวัลแล้ว
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                {/* end lasted round  */}

                                {/* history */}
                                {huayhoon && huayhoon.filter(x=>parseInt(x.status) !== 3).map(data => {
                                        return <NavLink to={`/bill/huay-hoon/${data.id}`}
                                                        className={'text-decoration-none'}
                                                        key={data.id}>
                                            <div className={'card card-transparent mb-3'}>
                                                <div className={'card-body bg-top'}>
                                                <div className="d-flex justify-content-between">
                                                    
                                                    <div>
                                                        <div>{data.name} งวดที่ : <span className="ball3x">{data.no}</span></div>
                                                        <div >วันที่ : <span className="ball3">{moment(data.startdate).format("DD/MM/YYYY")}</span></div>
                                                    </div>
                                                   
                                                    <div className="dimg2">
                                                    <img src={`/images/${data.photo}.png`} />
                                                    </div>
                                                    
                                                   
                                                                    </div>
                                                    <div className={'row mb-3 align-items-start boderline'}>
                                                   
                                                        <div className={'col-auto text-center'}>
                                                            <div>
                                                                <img className="img-fluid" alt={'result'}
                                                                     src={`${process.env.PUBLIC_URL}/images/icon/${data.reward > 0 ? `check`:`close`}.png`}
                                                                     style={{height: "23px"}}/>
                                                            </div>
                                                            <div>
                                                            {data.reward > 0 ? "ถูกรางวัล" : "ไม่ถูกรางวัล"}
                                                            </div>
                                                        </div>

                                                        <div className={'col'}>
                                                           
                                                            <div>
                                                            หมายเลขบิล : {data.billcode}
                                                            </div>
                                                            <div className={'mb-1'}>
                                                            วันที่ซื้อ : {data.createdAt}
                                                            </div>
                                                        </div>

                                                        <div className={'col-auto text-end'}>
                                                            <div>
                                                            ยอดรวม
                                                            </div>
                                                            <div className={'text-primary'}>
                                                                {numberWithCommas(data.amount)}  บาท
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </NavLink>
                                    })}
                                {/* end history */}

                            </div>
                        </div>
                    </div>
                    }

                   
                </div>
            </div>
        </div>
    );
}

export default HuayHoon;
