import PageHeader from "../components/PageHeader";
import {numberWithCommas} from "../utils/math";
import {useSelector, useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";
import {useState, useEffect} from "react";
import {Spinner, Table} from "react-bootstrap";
import { getbalance, memberwithdraw, checkwithdraw, setStep } from '../store/user'
import Modal from '../components/Modal'
import Navbarx from '../components/NavBar'
function Withdraw() {
    const dispatch = useDispatch()
    const {item, accountwithdraw, isLoadingLogin} = useSelector(state => state.user);
    const [show, setShow] = useState(false);
    const setShow2 =()=> {
        window.location.reload()
    }
   
    const [amount, setAmount] = useState("");
    useEffect(()=> {
        dispatch(getbalance({}))
        dispatch(checkwithdraw({}))
    },[])
    const withdraw = () => {
        if(parseInt(amount) < 100 || !amount) {
            setShow(true) 
        } else {
            if(isLoadingLogin === false) {
            dispatch(memberwithdraw({amount}))
            }
        }
    }


    return (
        <div className={'container withdraw'}>
            <div className={'row justify-content-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo logoSize={'80'} backPath={'/'}/>
                    {/* end page title */}


                    {/* announcement */}
                    {
                        accountwithdraw?.close === 1 &&  <div className={'mt-5 mb-3 card card-green profile'} data-aos="fade-up">
                        <div className={'card-body'}>
                            <img
                                alt={'announcement'}
                                height={'20px'}
                                width={'20px'}
                                className={'me-2'}
                                src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/> ถอนเงินได้ในเวลา (08.30 - 01.00 น. )
                        </div>
                    </div>
                    }
                   
                    {/* end announcement */}
                    {
    accountwithdraw?.close === 0 && <div className={'mt-5 mb-3 card card-green profile'} >
        <div className={'card-body'}>
            <div className="text-center pb-3">
            <img alt={'announcement'}
                                height={'20px'}
                                width={'20px'}
                                className={'me-2'}
                                src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/>
            </div>
        ระบบ ฝาก-ถอน ปิดทำการตั้งแต่ 02:00 น.
เปิดให้บริการอีกทีเวลา 08:30 น.
        </div>
    </div>
}

                    {/* withdraw card */}
                    {
                        accountwithdraw?.close === 1 && <div className={'mb-5'} data-aos="fade-up">
                        <div className={'card card-green mb-5 profile'}>
                            <div className={'card-body my-3'}>

                                <div className={'mb-3 text-center'}>
                                    <img
                                        alt={'bcel'}
                                        height={'50px'}
                                        width={'50px'}
                                        className={'rounded-circle'}
                                        src={`${item?.bankphoto}`}/>
                                </div>


                                <Table className={'table table-borderless'}>
                                    <tbody>
                                    <tr>
                                        <td className={'text-end w-50'}>
                                        ธนาคาร
                                        </td>
                                        <td className={'text-primary'}>
                                            {item.bankname}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={'text-end w-50'}>
                                        ชื่อบัญชี
                                        </td>
                                        <td className={'text-primary'}>
                                        {item.accname}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={'text-end w-50'}>
                                        เลขบัญชี
                                        </td>
                                        <td className={'text-primary'}>
                                        {item.accno}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>

                                <hr className={'my-3 border-secondary'}/>
                                {
                                    accountwithdraw?.stepwithdraw !== 1 &&  <div className={'gap-3 justify-content-center align-items-end'}>
                                    <div className={'d-flex justify-content-between align-items-end mb-2'}>
                                        <div>
                                        จำนวนเงิน
                                        </div>
                                        <div className={'h5 mb-0 lh-1 text-primary pointer'}
                                             >
                                            {numberWithCommas(accountwithdraw?.amount || 0)} บาท
                                        </div>
                                    </div>


                                    <div className={'mb-3 text-center bgwait'}>
                                        <div><img src={`/images/icon/time.png`} style={{maxWidth:50, paddingRight:5}} /></div>
                                    รอดำเนินการถอน
                                    </div>

                                   <div></div>

                                </div>
                                }

                                {
                                    accountwithdraw?.stepwithdraw === 1 && <div className={'gap-3 justify-content-center align-items-end'}>
                                    <div className={'d-flex justify-content-between align-items-end mb-2'}>
                                        <div>
                                        จำนวนเงิน
                                        </div>
                                        <div className={'h5 mb-0 lh-1 text-primary pointer'}
                                             >
                                            {numberWithCommas(item?.balance || 0)}
                                        </div>
                                    </div>


                                    <div className={'mb-3'}>
                                        <NumericFormat
                                            className={'form-control form-control-lg input-custom custom__input'}
                                            placeholder={'ถอนขั้นต่ำ 100 บาท'}
                                            inputMode={'numeric'}
                                            thousandSeparator=","
                                            allowLeadingZeros
                                            allowNegative={false}
                                            onChange={e => setAmount(e.target.value.replace(/\,/g, ''))}
                                            value={amount}/>
                                    </div>

                                    <div className={'btn btn-primary btn-lg w-100'}
                                         onClick={() => withdraw()}>
                                        ยืนยัน {isLoadingLogin === true && <Spinner />}
                                    </div>

                                </div>
                                }
                               

                            </div>
                        </div>
                    </div>
                    }
                    
                    {/* end withdraw card */}

                </div>
            </div>
            <Modal show={show} msg={`ຈຳນວນເງິນຖອນຂັ້ນຕ່ຳຄື 1,000 ກີບ`} onHide={()=>setShow(false)}  />
            <Modal show={accountwithdraw?.status === 3 && accountwithdraw?.type === 2} img={`/images/approve.png`} msg={`โอนเงินเสร็จเรียบร้อย`} onHide={()=>setShow2(false)}  />
            <Navbarx />
        </div>
    );
}

export default Withdraw;
