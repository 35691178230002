import {useDispatch, useSelector} from "react-redux";
import { useEffect} from "react";
import { getwebinfo} from "../store/user";
import Logo from "../components/Logo";
import {NavLink} from "react-router-dom";

function Register() {
   
    const { webinfo} = useSelector(state => state.user)
    const dispatch = useDispatch();
  
    useEffect(()=> {
        dispatch(getwebinfo({}))
    },[])

    return (
        <div className={'container'}>
            <div className={'row justify-content-center align-items-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} data-aos="fade-up">

                    <div className={'text-center'}>
                        <div className={'mb-3'}>
                            <Logo style={{maxHeight: '100px'}}/>
                        </div>
                    </div>

                    <div className={'card card-green mt-4 py-3'}>
                        <div className={'card-body'}>
                            <h3 className={'text-center'}>
                            ติดต่อ - สอบถาม
                            </h3>
                           
                            <div className={'text-center mb-4 text-secondary'}>
                                
                                
ผ่าน 2 ช่องทาง<br/>
                            </div>

                            <div className={'d-flex justify-content-center gap-2 mb-2 text-center'}>
                            <div className="pt-3">
             <a href={webinfo?.facebookurl} target="bank"><img src="/images/facebook.png" height={70} ></img></a> 
             <a href={webinfo?.facebookurl} target="bank"> <div className="btnsubmit44" >
             Facebook : {webinfo?.facebookid}
            </div>
            </a>
          </div>
                            </div>

                          
                            <div className={'d-flex justify-content-center  gap-2 mb-2 text-center'}>
                            <div className="pt-4">
          <a href={webinfo?.lineurl} target="bank"> <img src="/images/line-logo2.png" height={80} ></img></a>
          <a href={webinfo?.lineurl} target="bank"><div className="btnsubmit55" >
          Line : {webinfo?.lineid}
            </div>
            </a>
          </div>
                        </div>
                        
                               
                               
                            <div className="pt-2"></div>
                           



                            <div className="separator my-4">ท่านมีบัญชีใช้งานแล้ว</div>

                            <div className={'text-center mb-3'}>
                                <NavLink to={'/login'}>
                                    <div className={'w-100 btn btn-outline-primary btn-lg'}>เข้าสู่ระบบ</div>
                                </NavLink>
                            </div>
                            <div className={'text-center mb-3'}>
                                <NavLink to={'/register'}>
                                    <div className={'w-100 btn btn-primary btn-lg'}>สมัครสมาชิก</div>
                                </NavLink>
                            </div>

                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
