import React, { useState, useEffect } from "react";
import { getRemainingTimeUntilMstimestamp } from "../Lotto/ConutdownTimerUntils";
//import { FieldTimeOutlined } from "@ant-design/icons";

const defaultRemainTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
  end: 1,
};
const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainTime);
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainTime(countdownTimestampMs);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, []);
  function updateRemainTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMstimestamp(countdown));
  }
 return (
      <div className="appcountdown">
       
        
        {remainingTime.days == "00" &&
          remainingTime.hours == "00" &&
          remainingTime.minutes == "00" &&
          remainingTime.seconds == "00" &&
          remainingTime.end == 2 &&  
          <div className={'d-flex justify-content-center align-items-center countdown'}>
          <div className={'box'}>
              <div className={'text-center'}>
                  <div className={'time'}>
                  --
                  </div>
          
                  <div className={'small'}>
                  วัน
                  </div>
              </div>
          </div>
          
          <div className={'p-1 colon'}>
              :
          </div>
          
          <div className={'box'}>
              <div className={'text-center'}>
                  <div className={'time'}>
                  --
                  </div>
          
                  <div className={'small'}>
                  ชั่วโมง
                  </div>
              </div>
          </div>
          
          <div className={'p-1 colon'}>
              :
          </div>
          
          <div className={'box'}>
              <div className={'text-center'}>
                  <div className={'time'}>
                  --
                  </div>
          
                  <div className={'small'}>
                  นาที
                  </div>
              </div>
          </div>
          
          <div className={'p-1 colon'}>
              :
          </div>
          
          <div className={'box'}>
              <div className={'text-center'}>
                  <div className={'time'}>
                  --
                  </div>
          
                  <div className={'small'}>
                  วินาที
                  </div>
              </div>
          </div>
          </div>}
  
        {remainingTime.end != 2 && (

<div className={'d-flex justify-content-center align-items-center countdown'}>
<div className={'box'}>
    <div className={'text-center'}>
        <div className={'time'}>
        &nbsp;&nbsp;{remainingTime.days || '00'} &nbsp;วัน
        </div>

    </div>
</div>


<div className={'box'}>
    <div className={'text-center'}>
        <div className={'time'}>
        &nbsp;&nbsp;{remainingTime.hours || '00'} &nbsp;ชั่วโมง
        </div>
    </div>
</div>

<div className={'box'}>
    <div className={'text-center'}>
        <div className={'time'}>
        &nbsp;&nbsp;{remainingTime.minutes || '00'} &nbsp; นาที
        </div>
    </div>
</div>

<div className={'box'}>
    <div className={'text-center'}>
        <div className={'time'}>
        &nbsp;&nbsp;{remainingTime.seconds || '00'} &nbsp; วินาที
        </div>

    </div>
</div>
</div>
      
        )}
      </div>
    );
  
};

export default CountdownTimer;
