import {useEffect, useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import * as _ from "underscore";
import {numberWithCommas, pad} from "../../utils/math";
import {getBackNumber2, getBackNumber3} from "../../utils/tode";
import PageHeader from "../../components/PageHeader";
import {NumericFormat} from "react-number-format";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {createqrcode, getbalance, huayrate } from '../../store/user'
import { setTotal as setTotallist } from "../../store/tunsamai";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import CountdownTimer from "./CountdownTimer2";
function StockSelect() {

    const { huaysubId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoadingLogin, rateitem:huysubdatanew } = useSelector(state=>state.user)
   
    // cart
    const [selected, setSelected] = useState([]);

    useEffect(()=>{
        
        const items = localStorage.getItem('billItems')
        const obj = JSON.parse(items);
        console.log('obj', obj)
        const sumanount = obj.reduce((total, list) => {
            const sumdata = list.result.reduce((total1, list1) => total1 + parseInt(list1.amount),0)
            const alltt = total + parseInt(sumdata)
            return alltt
        } ,0)
        setSelected(obj)
        dispatch(setTotallist(sumanount))
        setTotalCart(sumanount)
    },[])
    
    const saveHuay = () => {
        const newitems = JSON.stringify(selected)
        localStorage.setItem('billItems', newitems)
        localStorage.setItem('huaysubId', huaysubId)
        const items = localStorage.getItem('billItems')
        const obj = JSON.parse(items);
        console.log('items', obj)
       
    }

    const {item} = useSelector(state => state.user);
    useEffect(()=> {
        dispatch(getbalance({}))
    },[])

     // mode
     const items = localStorage.getItem('rateitems')
     const obj = JSON.parse(items);
     const rateitem = localStorage.getItem('rateitem')
     const obj2 = JSON.parse(rateitem);
     const [huaysubdata] = useState(obj2);

    const [show, setShow] = useState(false);
    const [pay, setPay] = useState('')

    const showqr = (id) => {
        setPay(id)
        let bank = 1
        if(id === 1) {
            bank = 'credit'
        }
        if(id === 2) {
            bank = 'bcel'
        }
        if(id === 3) {
            bank = 'umoney'
        }
        if(isLoadingLogin === false) {
            const checktype = (type) => {
                if(type.id === 1) {
                    return '3'
                } else if(type.id === 5) {
                    return '2'
                } else if(type.id === 9) {
                    return '1top'
                } else if(type.id === 2) {
                    return '3tod'
                } else if(type.id === 6) {
                    return '2lower'
                } else if(type.id === 10) {
                    return '1lower'
                }
            }
           
            
            const huaysubId = localStorage.getItem('huaysubId')
            const items = localStorage.getItem('billItems')
            const newitems = JSON.parse(items);
            const newalldata = newitems.reduce((result, list) => {
                const type = checktype(list)
                const items = list.result.map(x=>({type, amount:x.amount, number:x.number}))
                return [...result, ...items]
            },[])
            
            if(item?.balance < total && id === 1) {
                
                setShow(true)
            } else {
                dispatch(createqrcode({amount:total, cart:newalldata, huaysubId:huaysubId, bankdata:bank, navigate}))
            }
            
        }
       
    }
    // mode
    const [modeList] = useState([
        {
            "id": 1,
            "name": "3ໂຕບົນ",
            "nameth": "3ตัวบน",
            "rate": 700,
            "numbertype": 3,
            "typerun": 31,
            "backnumber": 0,
            "active": true,
            "tode": 0,
            "remain": 900000000000000,
            "maxbet": 500000,
            "minbet": 1000,
            "perbet": 500000,
            "numberlimit": []
        },
        {
            "id": 2,
            "nameth": "3ตัวโต๊ด",
            "name": "3ໂຕໂຕ໊ດ",
            "rate": 120,
            "numbertype": 3,
            "typerun": 32,
            "backnumber": 0,
            "active": false,
            "tode": 1,
            "remain": 900000000000000,
            "maxbet": 500000,
            "minbet": 1000,
            "perbet": 500000,
            "numberlimit": []
        },
        {
            "id": 7,
            "nameth": "3ตัวกลับ",
            "name": "3ໂຕກັບ",
            "rate": 700,
            "numbertype": 3,
            "backnumber": 1,
            "active": false,
            "tode": 0
        },
        {
            "id": 5,
            "nameth": "2ตัวบน",
            "name": "2ໂຕບົນ",
            "rate": 90,
            "numbertype": 2,
            "typerun": 3,
            "backnumber": 0,
            "active": false,
            "tode": 0,
            "remain": 900000000000000,
            "maxbet": 1000000,
            "minbet": 1000,
            "perbet": 1000000,
            "numberlimit": []
        },
        {
            "id": 6,
            "name": "2ໂຕລ່າງ",
            "nameth": "2ตัวล่าง",
            "rate": 90,
            "numbertype": 2,
            "typerun": 4,
            "backnumber": 0,
            "active": false,
            "tode": 0,
            "remain": 900000000000000,
            "maxbet": 1000000,
            "minbet": 1000,
            "perbet": 1000000,
            "numberlimit": []
        },
        {
            "id": 8,
            "nameth": "2ตัวกลับ",
            "name": "2ໂຕກັບ",
            "rate": 450,
            "numbertype": 2,
            "backnumber": 1,
            "active": false,
            "tode": 0
        },
        {
            "id": 9,
            "nameth": "วิ่งบน",
            "name": "ວິ່ງບົນ",
            "rate": 3,
            "numbertype": 0,
            "backnumber": 0,
            "active": false,
            "tode": 0,
            "typerun": 1,
            "remain": 900000000000000,
            "maxbet": 5000000,
            "minbet": 10000
        },
        {
            "id": 10,
            "nameth": "วิ่งล่าง",
            "name": "ວິ່ງລ່າງ",
            "rate": 4,
            "numbertype": 0,
            "backnumber": 0,
            "active": false,
            "tode": 0,
            "typerun": 2,
            "remain": 900000000000000,
            "maxbet": 5000000,
            "minbet": 10000
        }
    ]);
    const [modeSelected, setModeSelected] = useState([]);

    // number on board
    const [numberList, setNumberList] = useState([]);
    const [numberRangeSelected, setNumberRangeSelected] = useState(0);

    // number type 0 2 3
    const [numberType, setNumberType] = useState(3);

    // total
    const [totalCart, setTotalCart] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        // init
        selectMode(modeList[0]);
        console.log('init')
    }, []);

    useEffect(() => {
        drawBoard();
        setTypingNumber("");

        console.log('num type, range change')
    }, [numberRangeSelected, numberType]);

    useEffect(() => {
        // calculate total
        let total = 0;
        let totalCart = 0;
        selected.map(mode => {
            mode.result.map(result => {
                total += Number(result.amount);
                totalCart += 1;
            })
        })
        setTotal(total)
        setTotalCart(totalCart)

        console.log('calculate')
    }, [selected]);

    const drawBoard = () => {
        let list = [];
        let pow = numberType > 0 ? 100 : 10
        let min = numberRangeSelected * pow;
        let max = min + pow;
        for (let i = min; i < max; i++) {
            list.push(pad(i, numberType));
        }

        setNumberList(list);
        console.log('draw board')
    }

    const selectNumber = (number) => {
        // check active mode
        let activeMode = _.filter(modeSelected, function (mode) {
            return mode.typeRun
        });

        if (activeMode.length > 0) {
            // check exist number
            let existNumber = _.some(selected, function (mode) {
                return _.findWhere(mode.result, {number: number});
            });

            if (existNumber) {
                // remove form all mode
                selected.map(mode => {
                    mode.result = _.without(mode.result, _.findWhere(mode.result, {
                        number: number
                    }));
                })
                setSelected([...selected])
                console.log('exist number', number, 'remove from all mode')
            } else {

                // check is back number
                let backNumberMode = _.findWhere(modeSelected, {backNumber: 1});
                if (backNumberMode) {

                    // get back number
                    let numbers = [];
                    if (activeMode[0].numberType === 3) {
                        numbers = getBackNumber3(number);
                    } else if (activeMode[0].numberType === 2) {
                        numbers = getBackNumber2(number);
                    }

                    // add number to all mode
                    activeMode.map(mode => {
                        let obj = _.findWhere(selected, {id: mode.id});

                        // set up obj numbers
                        let objNumbers = [];
                        numbers.map(number => {
                            objNumbers.push({mode: mode.id, 'number': number, amount: mode.minBet})
                        })


                        if (obj) {
                            // add
                            obj.result = [...obj.result, ...objNumbers];
                            obj.result = _.uniq(obj.result, function (list) {
                                return list.number;
                            })
                            setSelected([...selected]);

                        } else {
                            // create
                            setSelected(prevState => ([...prevState, {
                                ...mode,
                                result: _.uniq(objNumbers, function (list) {
                                    return list.number;
                                })
                            }]))
                        }
                    })

                    // check mode type number
                    console.log('add number with back mode', activeMode[0].numberType, numbers);

                } else {
                    // normal mode
                    console.log('add number normal', number);

                    // add number to all mode
                    activeMode.map(mode => {
                        let obj = _.findWhere(selected, {id: mode.id});
                        if (obj) {
                            // add
                            obj.result.push({mode: mode.id, 'number': number, amount: mode.minBet});
                            obj.result = _.uniq(obj.result, function (list) {
                                return list.number;
                            })
                            setSelected([...selected]);
                        } else {
                            // create
                            setSelected(prevState => ([...prevState, {
                                ...mode,
                                result: [{mode: mode.id, 'number': number, amount: mode.minBet}]
                            }]))
                        }
                    })
                }
            }
        } else {
            console.log('please select mode')
        }
    }

    const selectMode = (mode) => {
        let existMode = _.findWhere(modeSelected, {id: mode.id});
        if (existMode) {
            // remove mode
            setModeSelected(_.without(modeSelected, _.findWhere(modeSelected, {
                id: mode.id
            })));
        } else {

            // only 3 digit
            if (mode.numbertype === 3) {
                if (mode.typerun) {
                    // remove back
                    setModeSelected(_.without(modeSelected, _.findWhere(modeSelected, {
                        backNumber: 1,
                        tode: 0
                    })));
                } else {
                    // remove tode
                    setModeSelected(_.without(modeSelected, _.findWhere(modeSelected, {
                        tode: 1
                    })));
                }
            }

            let numType = modeSelected[0] ? modeSelected[0].numberType : null;
            if (mode.numbertype !== numType && modeSelected.length !== 0) {
                // reset if num type diff
                setModeSelected([]);
                setNumberRangeSelected(0)
            }

            // add active mode
            setModeSelected(prevState => [...prevState, {
                id: mode.id,
                numberType: mode.numbertype,
                tode: mode.tode,
                backNumber: mode.backnumber,
                typeRun: mode.typerun,
                maxBet: mode.maxbet,
                minBet: mode.minbet,
                name: mode.name,
                nameTH: mode.nameth,
                rate: mode.rate
            }]);

            // set number type
            setNumberType(mode.numbertype)
        }
    }
    const [payments] = useState([
        {id: 1, image: "/images/icon/credit.png", name: "Credit"},
       // {id: 2, image: "/images/icon/one.png", name: "BCEL One"},
        //{id: 3, image: "/images/icon/umoney.png", name: "U-money"}
    ])


    const [typingNumber, setTypingNumber] = useState('');
    const typing = (number) => {
        setTypingNumber(number);

        if (number.length === numberType) {
            // check exist number
            let existNumber = _.some(selected, function (mode) {
                return _.findWhere(mode.result, {number: number});
            });

            if (!existNumber) {
                selectNumber(number);
            }

            // reset form
            setTypingNumber("");
        } else if (numberType === 0) {
            // check exist number
            let existNumber = _.some(selected, function (mode) {
                return _.findWhere(mode.result, {number: number});
            });

            if (!existNumber) {
                selectNumber(number);
            }

            // reset form
            setTypingNumber("");
        }
    }

    const handleKeyDown = (e) => {
        if (isNaN(+e.key)) {
            e.preventDefault();
        }
    };

    const clickNumber = (number) => {
        let numbers = typingNumber + number;
        setTypingNumber(numbers);
        typing(numbers);
    }

    const clearCart = () => {
        Swal.fire({
            html: `<div class=""> แน่ใจแล้วใช่หรือไม่ ที่จะลบข้อมูล ? </div>`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: false,
            focusCancel: false,
            reverseButtons: true,
            confirmButtonText: "ลบ",
            cancelButtonText: "ปิด",
            customClass: {
                confirmButton: "bg-danger",
                cancelButton: "bg-secondary"
            }
        }).then(async (result) => {
            if (result.value) {
                setSelected([]);
            }
        })
    }

    return (
        <div className={'container hoon'}>
            <div className={'row justify-content-center min-vh-100 py-4'} data-aos="fade-up">
                <div className={'col-12'}>

                    {/* page title */}
                    <PageHeader logo backPath={'/lotto/stock'}/>
                    {/* end page title */}

                    {/* countdown */}
                    <h5 className={'mb-3 text-primary text-center'}><CountdownTimer countdownTimestampMs={huaysubdata?.enddate} /></h5>
                    {/* end countdown */}


                    <div className={'d-flex justify-content-center'}>
                        <div className={'row justify-content-center'} style={{maxWidth: "1000px"}}>
                            <div className={'col-12'}>
                                {/* announcement */}
                                <div className={'text-center'}>
                                                <div className={'xribbonlotto'}>
                                                    ปิดรับ {huysubdatanew.endtime}
                                                </div>
                                            </div>
                                            <div className={'mb-3 card card-green'}>
                               
                               <div className={`header position-relative ${huysubdatanew.bghead}`}>

                                   <div className={`ms-2 fw-bold`}>
                                       {huysubdatanew?.nameth} | วันที่ : {huysubdatanew?.startdate}
                                   </div>
                                   <div className={`flags position-absolute ${huysubdatanew?.bgphoto}`}>

                                   </div>
                               </div>
                              
                           </div>
                                {/* end announcement */}
                            </div>

                            <div className={'col-12 col-lg-6'}>

                                <div className={'card card-green'}>
                                    <div className={'card-body'}>

                                        <div className={'mb-3'}>
                                        จำนวน {totalCart} รายการ
                                        </div>

                                        <div className={'cart'}>
                                            {selected.map(mode => {
                                                return mode.result.length > 0 &&
                                                    <div className={'mb-3'} key={`${mode.name}`}>
                                                        <div className={'mb-2'}> {mode.nameTH}</div>
                                                        {mode.result.map(data => {
                                                            return <div
                                                                key={`${mode.name}_${data.number}`}>
                                                                <div className="input-group mb-3 cart-list">
                                                                    <div className="input-group-prepend ">
                                                                    <span
                                                                        className="input-group-text">{data.number}</span>
                                                                    </div>

                                                                    <NumericFormat
                                                                        className={`form-control btdis bet border-0 
                                                                    ${data.amount > mode.maxBet || data.amount < mode.minBet ? 'bg-danger' : ''}`}
                                                                        placeholder={'จำนวนเงิน'}
                                                                        inputMode={'numeric'}
                                                                        thousandSeparator=","
                                                                        allowLeadingZeros
                                                                        allowNegative={false}
                                                                        value={data.amount}
                                                                        disabled
                                                                    />

                                                                    <div className="input-group-prepend ">
                                                                    <span
                                                                        className="input-group-text reward h-100">
                                                                        <span className={'me-2'}>  ชะนะ</span>
                                                                         <span
                                                                             className={'me-2'}>  {numberWithCommas(data.amount * mode.rate)} </span>
                                                                        <span
                                                                            className={'badge bg-primary'}>  {mode.rate} ฿</span></span>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                            })}
                                        </div>



                                     
                                    </div>
                                </div>
                            </div>


                            <div className={'col-12 col-lg-6'}>

<div className={'card card-green'}>
    <div className={'card-body'}>


    <div className={'mb-3 bgttoal text-center'}>
                                        ยอดซื้อรวม : {numberWithCommas(total)} บาท
                                        </div>
    <div className={'text-primary h5 text-center'}>
                            เลือกวิธีชำระเงิน
                        </div>
                        {payments.map(data => {
                            return <div className={'card card-green mb-3'} key={`${data.id}`}>
                                <div className={'py-2 px-3'}>
                                    <div className={'d-flex gap-3 justify-content-between align-items-center'}>
                                        <div className={'d-flex gap-3 align-items-center'}>
                                            <img className="img-fluid img-payment"
                                                 src={`${process.env.PUBLIC_URL}${data.image}`}
                                                 alt={data.name}/>
                                            <div>
                                                <div className={'text-secondary'}>
                                                    จ่ายผ่าน
                                                </div>
                                                <div className={'text-primary'}>
                                                    {data.id !== 1 && data.name}
                                                    {
                                                        data.id === 1 && <span>กระเป๋าเงิน : {numberWithCommas(item?.balance || 0)}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-end'}>
                                            {
                                                isLoadingLogin === true && pay === data.id ? <button className={'btn btn-primary text-decoration-none'}  ><Spinner /></button> : <button className={'btn btn-primary text-decoration-none'} onClick={()=>showqr(data.id)} >ชำระเงิน</button>
                                            }
                                            
                                            {
                                                /*
<NavLink to={'/lotto/tunsamai/payment'}
                                                     className={'btn btn-primary text-decoration-none'}>
                                                เลือก
                                            </NavLink>
                                                */
                                            }
                                
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })}
        </div>
        </div>
        </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockSelect;
