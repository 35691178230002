import PageHeader from "../../components/PageHeader";

import {Table} from "react-bootstrap";
import {numberWithCommas} from "../../utils/math";
import {useSelector, useDispatch} from "react-redux";
import { APIURL } from "../../store/config";
import NavBar from '../../components/NavBar'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { bookhuay, checkqrcode } from '../../store/user'
import { useEffect } from "react";
function TunsamaiPayment() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {total, cart} = useSelector(state => state.tunsamai);
    const {accountdeposit, isLoadingLogin} = useSelector(state => state.user);
   
    const confirm = () => {
        const datasave = {betitemssave:cart, huaysubId:'2334433', sid:accountdeposit.sid, navigate}
        if(isLoadingLogin === false) {
            dispatch(bookhuay(datasave))
        }
    }

    useEffect(()=>{
        dispatch(checkqrcode({}))
    },[])

   
    return (
        <div className={'container withdraw'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo logoSize={'80'} backPath={'/lotto/tunsamai'}/>
                    {/* end page title */}

                    {/* withdraw card */}
                    <div className={'mb-5'} data-aos="fade-up">
                        <div className={'card card-green mb-5'}>
                            <div className={'card-body my-3'}>

                                <div className={'mb-3 text-center'}>
                                <img
                                        alt={'bcel'}
                                        height={'50px'}
                                        width={'50px'}
                                        className={'rounded-circle'}
                                        src={`https://100lankip2.com/images/bank/${accountdeposit?.bankphoto}`}/>
                                </div>

                                <Table className={'table table-borderless mb-0'}>
                                    <tbody>

                                    <tr>
                                        <td className={'text-end w-50'}>
                                        ยอดโอน
                                        </td>
                                        <td className={'text-primary'}>
                                            {numberWithCommas(accountdeposit?.amount || 0)} บาท
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>

                                <hr className={'mt-3 mb-1 border-secondary'}/>

                                <div className={'gap-3 justify-content-center align-items-end'}>

                                    <div className={'mb-3 text-center'}>

                                    {
                                            // status === 0 รอคิวอา
                                            accountdeposit?.status === 0 && <div>
                                            <div style={{height:80}}></div>
                                            <div className="ring">LOADING
                                        <span className='span1'></span>
                                      </div>
                                      <div style={{height:180}}></div>
                                      <div>รอคิวอา สักครู่</div>
                                      </div>
                                    }


                                        {
                                            accountdeposit?.qrcode && accountdeposit?.status === 1 && <div><img className={'img-fluid bg-white'} alt={'qr-code'}
                                            src={`${APIURL}/images/statement/${accountdeposit?.qrcode}`}/>
                                          <div></div></div>
                                        }


                                        {
                                            accountdeposit?.qrcode && accountdeposit?.status === 2 && <div>
                                            <div style={{height:80}}></div>
                                            <div className="ring">LOADING
                                        <span className='span1'></span>
                                      </div>
                                      <div style={{height:180}}></div>
                                      <div> <span>ຮັບແຈ້ງຂໍ້ມູນຝາກແລ້ວ <br /> ລໍຖ້າລະບົບຢືນຢັນ</span></div></div>
                                        }
                                    </div>



                                    {
                                        accountdeposit?.qrcode && accountdeposit?.status === 1 && <div className={'mb-3 text-center'}>
                                        <div className={'h5'}>
                                        ກະລຸນາໂອນພາຍໃນ : 01:00 ນາທີ
                                        </div>
                                        <div className={'text-primary'}>
                                        ເມື່ອໂອນແລ້ວກະລຸນາ ກົດ ຢືນຢັນຝາກ
                                        </div>

                                    </div>
                                    }
                                    
                                    
                                    {
                                        accountdeposit?.qrcode && accountdeposit?.status !== 2 && <div className={'btn btn-primary btn-lg w-100 d-none d-sm-block'}
                                        onClick={() => confirm()}>
                                            {
                                                isLoadingLogin === true ? <Spinner /> : 'ຢືນຢັນຝາກ'
                                            }
                                       
                                   </div>
                                    }
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end withdraw card */}


                    {/* under sm only */}
                    {
                        accountdeposit?.qrcode && accountdeposit?.status !== 2 && <div className={'container fixed-bottom d-sm-none d-block'} style={{maxWidth: '450px'}}>
                        <div className={'card card-green'}>
                            <div className={'card-body my-3'}>
                                <div className={'btn btn-primary btn-lg w-100 '}
                                     onClick={() => confirm()}>
                                    {
                                                isLoadingLogin === true ? <Spinner /> : 'ຢືນຢັນຝາກ'
                                            }
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {
                        accountdeposit?.status === 0 && <div className={'container fixed-bottom d-sm-none d-block'} style={{maxWidth: '450px'}}>
                        <div className={'card card-green'}>
                            <div className={'card-body my-3'}>
                                <div className={'btn btn-primary btn-lg w-100 '}
                                    >
                                    ຫ້າມອອກຈາກນີ້ຈົນກວ່າຈະໄດ້ຮັບ QRCode
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    
                    {/* end under sm only */}

                </div>
            </div>
            <NavBar />
        </div>
    );
}

export default TunsamaiPayment;
