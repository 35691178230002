import CustomNavLinkWithIcon from "./CustomNavLinkWithIcon";

function MainMenuBottom() {
    return (
        <div className={'fixed-bottom'}>
            <div className={'container main-menu pt-3 pb-2'} style={{maxWidth: '450px'}}>
                <div className={'d-flex justify-content-around text-center'}>
                    <CustomNavLinkWithIcon
                        to={'/'}
                        activeIcon={`${process.env.PUBLIC_URL}/images/icon/home_gray.svg`}
                        inActiveIcon={`${process.env.PUBLIC_URL}/images/icon/home.svg`}
                        text={'หน้าแรก'}
                    />

                    <CustomNavLinkWithIcon
                        to={'/lotto/stock'}
                        activeIcon={`${process.env.PUBLIC_URL}/images/icon/lotto_gray.svg`}
                        inActiveIcon={`${process.env.PUBLIC_URL}/images/icon/lotto.svg`}
                        text={'ซื้อหวย'}
                    />

                    <CustomNavLinkWithIcon
                        to={'/bill/huay-hoon'}
                        activeIcon={`${process.env.PUBLIC_URL}/images/icon/bill_gray.svg`}
                        inActiveIcon={`${process.env.PUBLIC_URL}/images/icon/bill.svg`}
                        text={'บิลหวย'}
                    />

                    <CustomNavLinkWithIcon
                        to={'/affiliate'}
                        activeIcon={`${process.env.PUBLIC_URL}/images/icon/affiliate_gray.svg`}
                        inActiveIcon={`${process.env.PUBLIC_URL}/images/icon/affiliate.svg`}
                        text={'แนะนำเพื่อน'}
                    />

                    <CustomNavLinkWithIcon
                        to={'/profile'}
                        activeIcon={`${process.env.PUBLIC_URL}/images/icon/profile_gray.svg`}
                        inActiveIcon={`${process.env.PUBLIC_URL}/images/icon/profile.svg`}
                        text={'ข้อมูลส่วนตัว'}
                    />
                </div>
            </div>
        </div>
    );
}

export default MainMenuBottom;
