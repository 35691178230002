import {useState, useEffect, useMemo, useRef} from "react";

import {useSelector, useDispatch} from "react-redux";


import PageHeader from "../components/PageHeader";

import { getbalance, memberchangpass, balanceweb } from "../store/user";

import { Spinner } from "react-bootstrap";
import { WEBTOKEN } from "../store/config";
import { numberWithCommas } from "../utils/math";
function Affiliate() {
   
    const { isLoadingBtn:isLoading, item, itemshistory } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(balanceweb({}))
    },[])

   

    return (<div className={'container affiliate'}>
        <div className={'row justify-content-center min-vh-100 py-4'}>
            <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                {/* page title */}
                <PageHeader logo backPath={'/'} />
                {/* end page title */}

                {/* affiliate id */}
                <div className={'card card-green mb-5'} data-aos="fade-up">

              

                    <div className={'card-body'}>
                    <table className={'table'}>
                            <thead>
                            <tr className={'text-secondary'}>
                                <td>
                                วันที่
                                </td>
                                <td>
                                ประเภท
                                </td>
                                <td className={'text-end'}>
                                รายละเอียด
                                </td>
                             
                            </tr>
                            </thead>

                            <tbody>
                            {itemshistory && itemshistory.map(data => {
                                return <tr key={`h_${data.id} txtrow`}>
                                    <td>
                                    {data.createdAt}
                                    </td>
                                    <td>
                                    {data.type} 
                                    </td>
                                    <td className={'text-end text-primary'}>
                                    จำนวน &nbsp;
                                    {
                                        parseFloat(data.amount) < 0 && <span className="text-info">{numberWithCommas(data.amount)}</span>
                                    }
                                    {
                                        parseFloat(data.amount) > 0 && <span className="text-primary">{numberWithCommas(data.amount)}</span>
                                    }<br/>
                                    <span className="text-secondary">เครดิตก่อน : {numberWithCommas(data.beforeBalance)}</span> <br />
                                    <span className="text-secondary">เครดิตหลัง : {numberWithCommas(data.afterBalance)}</span> <br />
                                   
                                   
                                    </td>
                                  
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* end affiliate id */}

              
                


               
            </div>
        </div>

    </div>);
}

export default Affiliate;
