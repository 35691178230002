import {useDispatch, useSelector} from "react-redux";
import { useRef, useEffect, useState} from "react";
import { memberregister, bankregister, setAffcode} from "../store/user";
import Logo from "../components/Logo";
import {NavLink, useNavigate} from "react-router-dom";
import { WEBTOKEN } from "../store/config";
import { Spinner } from "react-bootstrap";

function Register() {
    const navigate = useNavigate();
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const register = async () => {
        return navigate("/contact");
    }

    useEffect(()=> {
        const params = new URLSearchParams(window.location.search) // id=123
        let code = params.get('affcode') // 123 
        dispatch(setAffcode(code))
        dispatch(bankregister({}))
    },[])

    const [cbank, setcbank] = useState()
    const [show, setshow] = useState(false)
    const txtusername = useRef(null)
    const txtpassword = useRef(null)
    const txtbank = useRef(null)
    const txtaccname = useRef(null)
    const txtaccno = useRef(null)
  
    const signup = () => {
        const username = txtusername.current.value;
        const password = txtpassword.current.value;
        const bank = txtbank.current.value
        const accno = txtaccno.current.value;
        const accname = txtaccname.current.value;
       
        if(!username){
            txtusername.current.focus()
        } else if(!password && username){
            txtpassword.current.focus()
        } else if(bank == 'TT'){
            setcbank('TT')
        }else if(!accname){
            txtaccname.current.focus()
        }else if(!accno){
            txtaccno.current.focus()
        } else {
            setcbank('APP')
            const data = {
                username,
                password,
                accno:accno,
                accname:accname,
                affcode:user?.affcode,
                fkbank:bank,
                webtoken:WEBTOKEN,
                currency:'th'
            }
            dispatch(memberregister(data));
        }
        
    }
    return (
        <div className={'container'}>
            <div className={'row justify-content-center align-items-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} data-aos="fade-up">
                <div className={'text-center d-flex justify-content-between'}>
                   
                   <NavLink to={'/'}>
                                   <div className={'w-100 btn btn-outline-primary btn-sm'}><img class="img-fluid menu-icon" height={22} width={22} src="/images/icon/home.svg" alt="menu" /> หน้าแรก</div>
                               </NavLink>
                      
                      
                   </div>
                    <div className={'text-center'}>
                        <div className={'mb-3'}>
                            <Logo style={{maxHeight: '100px'}}/>
                        </div>
                    </div>

                    <div className={'card card-green mt-4 py-3'}>
                        <div className={'card-body'}>
                            <h3 className={'text-center'}>
                                สมัครสมาชิก
                            </h3>
                           
                            <div className={'text-center mb-4 text-secondary'}>
                                
                            </div>

                            <div className={'d-flex gap-2 mb-2'}>
                                <input ref={txtusername} name="username" className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ป้อนเบอร์มือถือ'}/>
                            </div>
                            {
                                user?.errcode == 403 &&  <div className="mb-3 text-danger text-center">เบอร์มือถือ ซ้ำ</div>
                               }
                            <div className={'d-flex gap-2 mb-2'}>
                                <input ref={txtpassword}  name="password" type="password" className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ป้อนรหัสผ่าน'}/>
                            </div>
                            <div className={'d-flex gap-2 mb-2'}>
                        <select name="banks" className={'form-control form-control-lg input-custom custom__input'} ref={txtbank} onChange={()=>setcbank('APP')} >
                            <option value="TT">เลือกธนาคาร</option>
                            {
                                user?.bankregis && user?.bankregis.filter(x=>x.name !=='Gtech').map(x=><option value={x._id} key={x._id}>{x.name}</option>)
                            }
                        
                        </select>
                        </div>
                        {
                            cbank == 'TT' && <div className="mb-1 text-danger text-center">ใส่ข้อมูลธนาคาร</div>
                        }
                         <div className={'d-flex gap-2 mb-2'}>
                        <input className="form-control form-control-lg input-custom custom__input" ref={txtaccname}  name="banksaccname" type="text"
                               placeholder="ป้อนชื่อบัญชี" />
                                </div>
                                <div className={'d-flex gap-2 mb-2'}>
                                <input className="form-control form-control-lg input-custom custom__input" ref={txtaccno}  name="banksnumber" type="text"
                               placeholder="ป้อนเลขบัญชี (ห้ามมี -)"/>
                                </div>
                                {
                                user?.errcode == 411 &&  <div className="mb-3 text-danger text-center">เลขบัญชี ซ้ำ</div>
                               }
                               
                            <div className="pt-2"></div>
                            <div className={'mb-4 border border-primary p-3 rounded '}>
                                ข้อกำหนดและเงื่อนไข  <br/>
                                1. ใช้บัญชีที่ลงทะเบียนไว้เท่านั้นสำหรับ การฝากเงิน <br/>
                                2. ชื่อ หรือ สกุล , ที่อยุ่ , อีเมล์ , เบอร์โทรศัพท์, บัญชีธนาคาร , IP แอดเดรส เดียวเท่านั้น  <br/>
                                3. สมาชิกต้องวางเดิมพัน จึงจะสามารถถอนเงินได้ ( ไม่สามารถฝาก และถอนเงินทันทีโดยที่ไม่วางเดิมพัน )  <br/>
                                4. ในกรณีตรวจพบว่าสมาชิก ฝาก/ถอน ผิดปกติเพื่อก่อกวน เอาเปรียบบริษัท หรือคาดว่าเป็นมิจฉาชีพ ทีมงานขอสงวนสิทธิ์ในการระงับยูสเซอร์ตรวจสอบ และตัดสิน
                            </div>


                            <div className={'text-center mb-3'}>
                                <div className={'w-100 btn btn-primary btn-lg'} onClick={signup}>สมัครสมาชิก {user?.isLoadingLogin === true && <Spinner size="sm" /> }</div>
                            </div>

                            <div className="separator my-4">ท่านเป็นสมาชิกอยู่แล้ว</div>

                            <div className={'text-center mb-3'}>
                                <NavLink to={'/login'}>
                                    <div className={'w-100 btn btn-outline-primary btn-lg'}>เข้าสู่ระบบ</div>
                                </NavLink>
                            </div>

                            <div className={'text-center mb-3'}>
                                <div className={'w-100 btn btn-danger btn-lg'} onClick={()=>register()}>
                                    ติดต่อ - สอบถาม
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="linefixed" onClick={()=>navigate('/contact')}><img width="150" height="231" src="/images/linethai.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/linethai.png 150w, /images/linethai.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
    );
}

export default Register;
