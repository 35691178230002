import PageHeader from "../components/PageHeader";
import {numberWithCommas} from "../utils/math";
import {NumericFormat} from "react-number-format";
import {useSelector, useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom'
import {useState, useEffect, useMemo} from "react";
import { createqrcodedeposit, checkqrcode } from '../store/user'
import { Spinner } from "react-bootstrap";
import Popup from '../components/PupupDeposit'
function Deposit() {
    const [amount, setAmount] = useState("");
    const [bank, setBank] = useState("kbank");
    const { isLoadingLogin, accountdeposit } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [payments] = useState([
        {id: 'bcel', image: "/images/icon/one.png", name: "BCEL One"},
       // {id: 'umoney', image: "/images/icon/umoney.png", name: "U-money"}
    ])

    const [showpop, setshowpop] = useState(true)

    const deposit = () => {
        if(isLoadingLogin === false) {
            dispatch(createqrcodedeposit({amount:amount, deposittype:'credit', bankdata:bank, navigate}))
        }
        
    }
    useEffect(()=>{
        dispatch(checkqrcode({}))
    },[])

    useMemo(()=> {
        if(accountdeposit?.qrcode){
            navigate('/lotto/tunsamai/depositconfirm')
        }
    },[accountdeposit?.qrcode])

    return (
        <div className={'container deposit'}>
            <div className={'row justify-content-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo logoSize={'80'} backPath={'/'}/>
                    {/* end page title */}


                    {/* announcement */}
                    {
                        accountdeposit?.close === 1 && <div className={'mt-5 mb-3 card card-green profile'} data-aos="fade-up">
                        <div className={'card-body'}>
                            <img alt={'announcement'}
                                height={'20px'}
                                width={'20px'}
                                className={'me-2'}
                                src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/> ฝากเงินในเวลา (07.30 - 01.00 น. )
                        </div>
                    </div>
                    }
                    
                    {/* end announcement */}
{
    accountdeposit?.close === 0 && <div className={'mt-5 mb-3 card card-green profile'} >
        <div className={'card-body'}>
            <div className="text-center pb-3">
            <img alt={'announcement'}
                                height={'20px'}
                                width={'20px'}
                                className={'me-2'}
                                src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/>
            </div>
        ระบบ ฝาก-ถอน ปิดทำการตั้งแต่ 02:00 น.
จะเปิดให้บริการภายในเวลา 08:30 น.
        </div>
    </div>
}

  {/* payments  */}
  
  
                    {/* end payments  */}

                    {/* deposit card */}
                    {
                        accountdeposit?.close === 1 && <div className={'mb-5'}>
                        <div className={'card card-green mb-5 profile'}>
                            <div className={'card-body my-3'}>

                                <div className={'mb-2'}>
                                    <NumericFormat
                                        className={'form-control form-control-lg input-custom custom__input'}
                                        placeholder={'จำนวนฝากขั้นต่ำ 50 บาท'}
                                        inputMode={'numeric'}
                                        thousandSeparator=","
                                        allowLeadingZeros
                                        disabled
                                        allowNegative={false}
                                        onChange={e => setAmount(e.target.value.replace(/\,/g, ''))}
                                        value={amount}/>
                                </div>


                                <div className={'mb-4'}>
                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[50, 100, 200].map(number => {
                                            return <div className={'btn-money flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[300, 500, 800].map(number => {
                                            return <div className={'btn-money flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[1000, 3000, 5000].map(number => {
                                            return <div className={'btn-money2 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>
                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[6000, 8000, 10000].map(number => {
                                            return <div className={'btn-money2 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                  
                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[30000, 50000, 100000].map(number => {
                                            return <div className={'btn-money3 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>


                                </div>

                                <div className={'gap-3 justify-content-center align-items-end'}>

                                    <div className={'btn btn-primary btn-lg w-100'}
                                         onClick={() => deposit()}>
                                        ขอ QR Code ฝากเงิน { isLoadingLogin === true && <Spinner size="sm" />}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    }
                    
                    {/* end deposit card */}

                </div>
            </div>
            {
                /*
<Popup  show={showpop} onHide={()=>setshowpop(false)} />
                */
            }
            
        </div>
    );
}

export default Deposit;
