import Logo from "../components/Logo";
import {NavLink, useNavigate} from "react-router-dom";
import {login, memberlogin} from "../store/user";
import {useDispatch, useSelector} from "react-redux";
import { useRef, useEffect} from "react";
import { WEBTOKEN, LG } from '../store/config'
import { Spinner } from "react-bootstrap";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.user)


    /*
    const signIn = async () => {
        await dispatch(login());
        return navigate("/");
    }
    */

    useEffect(()=> {
        localStorage.removeItem('access_token')
        localStorage.removeItem('username')
        return navigate("/");
    }, [])
    const txtusername = useRef(null)
    const txtpassword = useRef(null)

    
    const signIn = async () => {

        const username = txtusername.current.value;
        const password = txtpassword.current.value;
        if(!username){
            txtusername.current.focus()
        } else if(!password && username){
            txtpassword.current.focus()
        } else {
            const data = {
                username,
                password,
                WEBTOKEN
            }
            if(user?.isLoadingLogin === false) {
                dispatch(memberlogin(data));
            }
            
        }
        
        /*
        await dispatch(login());
        return navigate("/");
        */
    }

    return (
        <div className={'container'}>
            <div className={'row justify-content-center align-items-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} data-aos="fade-up">

                    <div className={'text-center'}>
                        <div className={'mb-3'}>
                            <Logo style={{maxHeight: '100px'}}/>
                        </div>
                    </div>

                    <div className={'card card-green mt-4 py-3'}>
                        <div className={'card-body'}>
                            <h3 className={'text-center'}>
                                ເຂົ້າສູ່ລະບົບ
                            </h3>

                            <div className={'text-center mb-4 text-secondary'}>
                                ลงทะเบียนสมาชิกใหม่หรือเข้าสู่ระบบง่ายๆ <br/>เพียงระบุเบอร์โทรศัพท์ของคุณเท่านั้น
                            </div>

                            <div className={'d-flex gap-2 mb-3'}>
                                <input ref={txtusername} className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'เบอร์มือถือ'}/>
                            </div>

                            <div className={'d-flex gap-2 mb-4'}>
                                <input type="password" ref={txtpassword} className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'รหัสผ่าน'}/>
                            </div>

                            {
                        user.item?.status == 3 && <div className="text-danger text-center">ບໍ່ພົບຂໍ້ມູນສະມາຊິກ ກະລຸນາປ້ອນໃໝ່ອີກເທື່ອ</div>
                }

                            <div className={'text-center mb-3'}>
                                {
                                    user?.isLoadingLogin === true ? <div className={'w-100 btn btn-primary btn-lg'}>ເຂົ້າສູ່ລະບົບ <Spinner size="sm" /></div> : <div className={'w-100 btn btn-primary btn-lg'} onClick={signIn}>ເຂົ້າສູ່ລະບົບ</div>
                                }
                                
                            </div>

                            <div className="separator my-4">ຍັງບໍ່ທັນເປັນສະມາຊິກ</div>

                            <div className={'text-center mb-3'}>
                                <NavLink to={'/register'}>
                                    <div className={'w-100 btn btn-outline-primary btn-lg'}>ສະຫມັກ ສະມາຊິກ</div>
                                </NavLink>
                            </div>

                            <div className={'text-center mb-3'}>
                                <div className={'w-100 btn btn-danger btn-lg'}>
                                    ຕິດຕໍ່ - ສອບຖາມ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
