import PageHeader from "../../components/PageHeader";
import {numberWithCommas} from "../../utils/math";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { getbookhuay, rejectbill, setReject } from '../../store/user'
import  Navbar from '../../components/NavBar'
import { Spinner } from "react-bootstrap";
import * as _ from "underscore";
import moment from "moment";
import {NumericFormat} from "react-number-format";
import Swal from "sweetalert2";
import Modal from '../../components/Modal'
function MyBill() {


    const dispatch = useDispatch()
    const { roundId } = useParams()
    const { huayitem, isLoadingBill, showReject, isLoadingBtn } = useSelector(state=>state.user)
    const setShow = () => {
        dispatch(setReject())
    }
    useEffect(()=> {
        dispatch(getbookhuay({roundId}))
    }, [roundId])


    const rejecthuay = () => {
        Swal.fire({
            html: `<div class=""> แน่ใจแล้วที่จะยกเลิกบิล ใช่หรือไม่  ? </div>`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: false,
            focusCancel: false,
            reverseButtons: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ปิด",
            customClass: {
                confirmButton: "bg-danger",
                cancelButton: "bg-secondary"
            }
        }).then(async (result) => {
            if (result.value) {
                if(isLoadingBtn === false) {
                    dispatch(rejectbill({billid:roundId, huaysubId:huayitem?.huaysubId}))
                }
            }
        })
    }

    return (
        <div className={'container my-bill'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo backPath={'/bill/huay-thai'}/>
                    {/* end page title */}

                    {isLoadingBill === true ?  <div>
        <div style={{height:80}}></div>
        <div className="ring">LOADING
    <span className='span1'></span>
  </div>
  <div style={{height:180}}></div>
 
  </div> :  <div> <div className={'card card-green mb-3'}>
                        <div className={'card-body'}>
                        <div className={'d-flex justify-content-center text-secondary'}>
                                <div className="text-center dimg">
                                    <img src={`/images/th.png`} />
                                    <div>{huayitem?.nameth}</div>
                                    </div>
                        </div>
                            <div className={'d-flex justify-content-between text-secondary'}>
                                <div>
                                    <div>
                                        งวดที่
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                        {huayitem?.no}
                                    </div>

                                </div>
                                <div className={'text-end'}>
                                    <div>
                                        วันที่
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                        {moment(huayitem?.enddate).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                            </div>
                            <div className={'d-flex justify-content-between text-secondary boderline'}>
                                <div>
                                   
                                    <div className={'text-primary h5 mb-0'}>
                                    ผลรางวัล
                                    </div>
                                    <div className={'text-primary h5 mb-0 pt-2'}>
                                    2ตัวล่าง
                                    </div>

                                </div>
                                <div className={'text-end'}>
                                   
                                    <div className={'text-primary h5 mb-0'}>
                                        {huayitem?.bet6result?.length === 0 && '-'}
                                    
                                    </div>
                                    {
                                        huayitem?.bet6result?.length > 0 && <div><div className={'text-primary h5 mb-0'}>
                                       
                                        <span className="ball">{huayitem?.bet6result[3]}</span>
                                        <span className="ball">{huayitem?.bet6result[4]}</span>
                                        <span className="ball">{huayitem?.bet6result[5]}</span>
                                        </div>
                                        <div className={'text-primary h5 mb-0 pt-2'}>
                                        <span className="ball">{huayitem?.bet2lower[0]}</span>
                                        <span className="ball">{huayitem?.bet2lower[1]}</span>
                                       
                                        </div>
                                        </div>
                                    }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end round */}

                    {/* summary */}
                    
                    <div className={'card card-green mb-5'}>
                        <div className={'card-body'}>
                           

                            <div className={'mb-4 cart'}>
                               {
                                huayitem?.items && huayitem?.items.map((x, index) =>  <div className={'mb-3'} key={`${x.type}${index}`}>
                                <div className={'mb-2'}> {x.nameth}</div>
    {x?.items && x?.items.map(data => {
                                    return <div
                                        key={`${data.id}_${data.number}`}>
                                        <div className="input-group mb-3 cart-list">
                                            <div className="input-group-prepend">
                                            <span
                                                className="input-group-text mxnumber">{data.number}</span>
                                            </div>
                                        
                                            <NumericFormat
                                                className={`form-control bet border-0 
                                            ${data.reward > 0 ? 'bg-dangerx2' : 'bg-dangerx2'}`}
                                                placeholder={'จำนวนเงิน'}
                                                inputMode={'numeric'}
                                                thousandSeparator=","
                                                allowLeadingZeros
                                                allowNegative={false}
                                                value={data.amount}
                                                disabled
                                            />

                                            <div className="input-group-prepend ">
                                            <span
                                                className="input-group-text rewardx h-100">
                                                    {
                                                        //data.reward > 0 ? <span className={'me-2 rewardx'}>ຊະນະ</span>:<span className={'me-2'}>ແພ້</span>
                                                    }
                
                                                    
                                                
                                                 <span
                                                     className={data.reward > 0 ? 'me-2 rewardx' : huayitem.status !==3 ? 'me-2 rewardnox' : 'me-2 rewardnox2'}>  {data.reward > 0 ? `+${numberWithCommas(data.amount * data.rate)}` : huayitem.status !==3 ? `-${numberWithCommas(data.amount)}` : `อัตรา`} </span>
                                               
                                                <span
                                                    className={'badge bg-primary'}>  {data.rate}</span></span>
                                            </div>
                                          
                                        </div>
                                    </div>
                                })}

                                </div>)
                               }
                           

                                
                                {/* total */}
                                <div className={'mt-3 d-flex justify-content-between'}>
                                    <div className={'text-secondary'}>
                                        ยอดทั้งหมด:
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                        {numberWithCommas(huayitem.amount || 0)} บาท
                                    </div>
                                </div>
                                {
                                    huayitem.reward > 0 && <div className={'mt-3 d-flex justify-content-between'}>
                                    <div className={'text-secondary'}>
                                        ได้รางวัล:
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                       <div className="number overbill">{numberWithCommas(huayitem.reward || 0)}</div> 
                                    </div>
                                </div>
                                }
                                

                                {/* end  total */}
                            </div>


                            {/* info */}
                           

                            {
                                huayitem.billcode &&  <div className={'card card-pink'}>
                                <div className={'card-body'}>
                                    เลขบิล: {huayitem.billcode} <br/>
                                    จ่ายผ่าน: {huayitem.note} <br/>
                                    วันที่: {moment(huayitem.createdAt).format("DD/MM/YYYY HH:mm")}
                                    <br />
                                    {
                                huayitem?.status === 2 && <span className="btn btn-danger btn-sm mt-3" >สถานะ : ยกเลิกบิล</span>
                            }
                                </div>
                            </div>
                            }

                         
                           
                            {/* end info */}
                            {
                                huayitem?.status === 3 && <button className="btn btn-primary btn-block mt-3" onClick={()=>rejecthuay()}>
                                    ยกเลิกบิล
                                    {isLoadingBtn === true && <Spinner />}
                                    </button>
                            }
                            
                            
                        </div>
                    </div>
                    {/* end summary */}</div>}
                    {/* round */}
                               
                   
                </div>
            </div>
        <Navbar />
        <Modal show={showReject} msg={'ໝົດເວລາແລ້ວ'} onHide={()=>setShow(false)}  />
        </div>
    );
}

export default MyBill;
