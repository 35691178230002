import {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader";
import {numberWithCommas} from "../../utils/math";
import {Nav, Spinner} from "react-bootstrap";
import {Link, NavLink, useNavigate} from "react-router-dom";
import BillMenu from "../../components/BillMenu";
import { getbillhuay } from "../../store/tunsamai";
import * as _ from "underscore";
import moment from "moment";
import CountdownTimer from "./CountdownTimer";
function HuayThai() {
   
    const checkimg = (q, type) => {
        const bill = [
            {id: 1, image: "01", name: "ປານ້ອຍ", numbers: ['01', '41', '81']},
            {id: 2, image: "02", name: "ຫອຍ", numbers: ['02', '42', '82']},
            {id: 3, image: "03", name: "ຫ່ານ", numbers: ['03', '43', '83']},
            {id: 4, image: "04", name: "ນົກຍູງ", numbers: ['04', '44', '84']},
            {id: 5, image: "05", name: "ສິງ", numbers: ['05', '45', '85']},
            {id: 6, image: "06", name: "ເສືອ", numbers: ['06', '46', '86']},
            {id: 7, image: "07", name: "ຫມູ", numbers: ['07', '47', '87']},
            {id: 8, image: "08", name: "ກະຕ່າຍ", numbers: ['08', '48', '88']},
            {id: 9, image: "09", name: "ຄວາຍ", numbers: ['09', '49', '89']},
            {id: 10, image: "10", name: "ນາກນ້ຳ", numbers: ['10', '50', '90']},
            {id: 11, image: "11", name: "ໝາ", numbers: ['11', '51', '91']},
            {id: 12, image: "12", name: "ມ້າ", numbers: ['12', '52', '92']},
            {id: 13, image: "13", name: "ຊ້າງ", numbers: ['13', '53', '93']},
            {id: 14, image: "14", name: "ແມວບ້ານ", numbers: ['14', '54', '94']},
            {id: 15, image: "15", name: "ຫນູ", numbers: ['15', '55', '95']},
            {id: 16, image: "16", name: "ເຜິ້ງ", numbers: ['16', '56', '96']},
            {id: 17, image: "17", name: "ນົກຍາງ", numbers: ['17', '57', '97']},
            {id: 18, image: "18", name: "ແມວປ່າ", numbers: ['18', '58', '98']},
            {id: 19, image: "19", name: "ກະເບື້ອ", numbers: ['19', '59', '99']},
            {id: 20, image: "20", name: "ຂີ້ເຂັບ", numbers: ['20', '60', '00']},
            {id: 21, image: "21", name: "ນົກແອ່ນ", numbers: ['21', '61']},
            {id: 22, image: "22", name: "ນົກກາງແກ", numbers: ['22', '62']},
            {id: 23, image: "23", name: "ລິງ", numbers: ['23', '63']},
            {id: 24, image: "24", name: "ກົບ", numbers: ['24', '64']},
            {id: 25, image: "25", name: "ແຫຼວ", numbers: ['25', '65']},
            {id: 26, image: "26", name: "ນາກບິນ", numbers: ['26', '66']},
            {id: 27, image: "27", name: "ເຕົ່າ", numbers: ['27', '67']},
            {id: 28, image: "28", name: "ໄກ່", numbers: ['28', '68']},
            {id: 29, image: "29", name: "ອ່ຽນ", numbers: ['29', '69']},
            {id: 30, image: "30", name: "ປາໃຫຍ່", numbers: ['30', '70']},
            {id: 31, image: "31", name: "ກຸ້ງ", numbers: ['31', '71']},
            {id: 32, image: "32", name: "ງູ", numbers: ['32', '72']},
            {id: 33, image: "33", name: "ແມງມຸມ", numbers: ['33', '73']},
            {id: 34, image: "34", name: "ກວາງ", numbers: ['34', '74']},
            {id: 35, image: "35", name: "ແບ້", numbers: ['35', '75']},
            {id: 36, image: "36", name: "ເຫງັນ", numbers: ['36', '76']},
            {id: 37, image: "37", name: "ຫຼິ່ນ", numbers: ['37', '77']},
            {id: 38, image: "38", name: "ເໝັ້ນ", numbers: ['38', '78']},
            {id: 39, image: "39", name: "ກະປູ", numbers: ['39', '79']},
            {id: 40, image: "40", name: "ນົກອິນຊີ", numbers: ['40', '80']},
        ]

        const newnumber = `${q}`
        let nnumber = `${q}`
        if(newnumber.length === 1) {
            nnumber = `0${q}`
        }else if(newnumber.length > 2) {
            nnumber = newnumber.slice(-2)
        }else {
            nnumber = `${q}`
        }

        const data  = _.find(bill, function (data) {
            return data.numbers.includes(nnumber);
        });

        return data?.[type]
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {  isLoading, userlast, detail} = useSelector(state=>state.tunsamai)
    
    useEffect(()=> {
        dispatch(getbillhuay({}))
    },[])

    const gopagedetail = (id) => {
        navigate(`/bill/huay-thai/${id}`)
    }

    console.log('detail', detail)
    return (
        <div className={'container'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader backPath={'/lotto/tunsamai'} logo />
                    {/* end page title */}

                    <BillMenu/>
                    {
                        isLoading === true ? <div>
                        <div style={{height:80}}></div>
                        <div className="ring">LOADING
                    <span className='span1'></span>
                  </div>
                  <div style={{height:180}}></div>
                 
                  </div> : <div className={'bill'}>
                        {/* tab menu */}
                        <div className={'bill-tab'}>
                            <Nav variant="tabs" defaultActiveKey="huay-thai"
                                 justify>
                                <Nav.Item className={'pe-1'}>
                                    <Nav.Link eventKey="huay-thai" as={Link} to={`/bill/huay-thai`}>
                                        <img className="icon" alt={'menu'}
                                             src={`${process.env.PUBLIC_URL}/images/icon/tunsamai_active.png`}/>
                                        รัฐบาลไทย
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item className={'ps-1'}>
                                    <Nav.Link eventKey="huay-hoon" as={Link} to={`/bill/huay-hoon`}>
                                        <img className="icon" alt={'menu'}
                                             src={`${process.env.PUBLIC_URL}/images/icon/namsud_gray.png`}/>
                                        หวยหุ้น
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        {/* end  tab menu */}

                        <div className={'card card-green mb-5'}>
                            <div className={'card-body'}>
                                <div className={'mb-5'}>

                                    {/* countdown */}
                                    {
                                        userlast?.now?.head?.bet6result?.length === 0 &&  <div className={'card card-dark mb-3'}>
                                        <div className={'card-body'}>
                                            <div className={'text-center  text-primary mb-3'}>
                                            งวดวันที่ : {moment(userlast?.now?.head?.enddate).format("DD/MM/YYYY")}
                                            </div>

                                            <div className={'text-center text-secondary'}>
                                            เหลือเวลา
                                            </div>

                                            <div className={'text-center'}>
                                            {
                                    isLoading === true ? '' :  <CountdownTimer countdownTimestampMs={userlast?.now?.head?.enddate} />
                                 }
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {
                                        // ออกรางวัลแล้ว
                                        userlast?.now?.head?.bet6result?.length > 0 &&  <div className={'card card-dark mb-3'}>
                                        <div className={'card-body'}>
                            <div className={'row align-items-start'}>
                               
                             
                                <div className={'col'}>
                                    <div className={'text-primary mb-3'}>
                                    งวดวันที่ : {moment(userlast?.now?.head?.enddate).format("DD/MM/YYYY")}
                                    </div>

                                    <div className={'row text-center justify-content-center'}>
                                        
                                        {[userlast?.now?.head?.bet6result[0], userlast?.now?.head?.bet6result[1], userlast?.now?.head?.bet6result[2], userlast?.now?.head?.bet6result[3], userlast?.now?.head?.bet6result[4], userlast?.now?.head?.bet6result[5]].map((number, index) => {
                                            return <div className={'col px-1'} key={index}>
                                                <h5 className={'result mb-0'}>
                                                    {number}
                                                </h5>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            
                            {
                                userlast?.now?.head?.bet2lower.length > 0 && <div className={'col-12 pe-3'}>
                                    
                                <div className={'d-flex justify-content-between mb-3'}>
                                    <div className={'text-secondary'}>
                                    
                                    </div>
                                    <div className={'pt-2 row text-right justify-content-center'}>2 ตัวล่าง
                                    {[userlast?.now?.head?.bet2lower[0],userlast?.now?.head?.bet2lower[1]].map((number, index) => {
                                        return <div className={'col px-1'}
                                                    key={`${number}_${index}`}>
                                            <h5 className={'result mb-0 p-3'}>
                                                {number}
                                            </h5>
                                        </div>
                                    })}
                                    </div>
                                </div>
                            </div>
                            }
                            

                            
                        </div>
                                    </div>
                                    }

                                    {
                                        !userlast?.now &&  <div className={'card card-dark mb-1'}>
                                        <div className={'card-body'}>
                                            <div className={'text-center  text-primary mb-3'}>
                                            ปิดรอบ
                                            </div>

                                            <div className={'text-center text-secondary'}>
                                                
                                            </div>

                                            <div className={'text-center text-white'}>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    }
                                   
                                    {/* end countdown */}

                                    {/* current */}
                                    {detail?.total > 0 && <div className="p-2 bmoms">ยอดซื้อทั้งหมด {numberWithCommas(detail?.total)} บาท</div>}
                                    {userlast?.now?.bill && userlast?.now?.bill.map(data => {
                                        return <NavLink to={`/bill/huay-thai/${data.id}`}
                                                        className={'text-decoration-none'}
                                                        key={data.id}>
                                            <div className={'card card-transparent mb-3'} >
                                                <div className={'card-body'}>
                                                    <div className={'row mb-3 align-items-start'}>
                                                        <div className={'col-auto text-center'}>
                                                            {
                                                                 data.reward > 0 &&   <div>
                                                                 <img className="img-fluid" alt={'result'}
                                                                      src={`${process.env.PUBLIC_URL}/images/icon/check_circle.png`}
                                                                      style={{height: "23px"}}/>
                                                             </div>
                                                            }
                                                            {
                                                                data.reward === 0 && data.status === 4 &&  <div>
                                                                <img className="img-fluid" alt={'result'}
                                                                     src={`${process.env.PUBLIC_URL}/images/icon/cancel.png`}
                                                                     style={{height: "23px"}}/>
                                                            </div>
                                                            }
                                                            {
                                                                data.reward === 0 && data.status === 3 &&  <div>
                                                                <img className="img-fluid" alt={'result'}
                                                                     src={`${process.env.PUBLIC_URL}/images/icon/help.png`}
                                                                     style={{height: "23px"}}/>
                                                            </div>
                                                            }
                                                           
                                                            <div>
                                                                {
                                                                    data.status === 3 && 'รอผล'
                                                                }
                                                                {
                                                                    data.status === 4 && 'เสีย'
                                                                }
                                                                {
                                                                    data.status === 6 && 'ชะนะ'
                                                                }
                                                           
                                                            </div>
                                                        </div>

                                                        <div className={'col'}>
                                                            <div className={'mb-1'}>
                                                            วันที่ : {moment(data.createdAt).format("DD/MM/YYYY HH:mm")}
                                                            </div>
                                                            <div>
                                                            เลขบิล: : {data.billcode}
                                                            </div>
                                                        </div>

                                                        <div className={'col-auto text-end'}>
                                                            <div>
                                                            ยอดรวม
                                                            </div>
                                                            <div className={'text-primary'}>
                                                                {numberWithCommas(data.amount)} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                data.reward > 0 && <div className={'row mb-3 align-items-start'}>
                                                <div className={'col-auto text-center'}>
                                                ได้รางวัล : <span className="number overbill">{numberWithCommas(data.reward)}</span> บาท
                                                    </div>
                                            </div>
                                            }
                                      
                                                      
                                                   
                                                </div>
                                            </div>
                                        </NavLink>
                                    })}
                                </div>
                                {/* end current */}

                                {/* lasted round */}
                                {
                        isLoading === true ? <div className="text-center"></div> :  <div className={'card card-dark mb-3'} >
                        <div className={'card-body'}>
                            <div className={'row align-items-start'}>
                               
                                <div className={'col'}>
                                    <div className={'text-primary mb-3'}>
                                    งวดวันที่ : {moment(userlast?.before?.head?.enddate).format("DD/MM/YYYY")}
                                    </div>

                                    <div className={'row text-center justify-content-center'}>
                                        {[userlast?.before?.head?.bet6result[0], userlast?.before?.head?.bet6result[1], userlast?.before?.head?.bet6result[2], userlast?.before?.head?.bet6result[3], userlast?.before?.head?.bet6result[4], userlast?.before?.head?.bet6result[5]].map((number, index) => {
                                            return <div className={'col px-1'} key={index}>
                                                <h5 className={'result mb-0'}>
                                                    {number}
                                                </h5>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className={'col-12 pe-3'}>
                                                <div className={'d-flex justify-content-between mb-3'}>
                                                    <div className={'text-secondary'}>
                                                   
                                                    </div>
                                                    <div className={'pt-2 row text-right justify-content-center'}>2ตัวล่าง
                                                    {[userlast?.before?.head?.bet2lower[0],userlast?.before?.head?.bet2lower[1]].map((number, index) => {
                                                        return <div className={'col px-1'}
                                                                    key={`${number}_${index}`}>
                                                            <h5 className={'result mb-0 p-3'}>
                                                                {number}
                                                            </h5>
                                                        </div>
                                                    })}
                                                    </div>
                                                </div>
                                            </div>

                        </div>
                    </div>
                    }
                               
                                {/* end lasted round  */}
                                {detail?.total2 > 0 && <div className="p-2 bmoms">ยอดซื้อทั้งหมด {numberWithCommas(detail?.total2)} บาท</div>}
                                {/* history */}
                                {userlast?.before?.bill && userlast?.before?.bill.map(data => {
                                    return <div onClick={() => gopagedetail(data.id)} className={'card card-transparent mb-3'}
                                                key={data.id}>
                                        <div className={'card-body'}>
                                            <div className={'row mb-3 align-items-start'}>
                                                <div className={'col-auto text-center'}>
                                                    <div>
                                                        <img className="img-fluid" alt={'result'}
                                                             src={`${process.env.PUBLIC_URL}/images/icon/${data.reward > 0 ? "check_circle" : "cancel"}.png`}
                                                             style={{height: "23px"}}/>
                                                    </div>
                                                    <div>
                                                        {data.reward > 0 ? "ถูกรางวัล" : "ไม่ถูกรางวัล"}
                                                    </div>
                                                </div>

                                                <div className={'col'}>
                                                    <div className={'mb-1'}>
                                                    วันที่ : {moment(data.createdAt).format("DD/MM/YYYY HH:mm")}
                                                    </div>
                                                    <div>
                                                    เลขบิล : {data.billcode}
                                                    </div>
                                                </div>

                                                <div className={'col-auto text-end'}>
                                                    <div>
                                                    ยอดรวม
                                                    </div>
                                                    <div className={'text-primary'}>
                                                        {numberWithCommas(data.amount)} บาท
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                data.reward > 0 && <div className={'row mb-3 align-items-start'}>
                                                <div className={'col-auto text-center'}>
                                                ได้รางวัล : <span className="number overbill">{numberWithCommas(data.reward)}</span> บาท
                                                    </div>
                                            </div>
                                            }
                                            

                                         
                                        </div>
                                    </div>
                                })}
                                {/* end history */}

                            </div>
                        </div>
                    </div>
                    }
                    
                </div>
            </div>
           
        </div>
    );
}

export default HuayThai;
